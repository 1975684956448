import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 自定义全局组件
import PCUI from './components/pc/pages/index' // pc端banner组件
import MobileUI from './components/mobile/pages/index' // 移动端banner组件
// 重置样式表
import '@/assets/css/reset.css'
import './assets/icons/iconfont.css'

// import setRem from './utils/rem'
import { setRem } from './utils/rem'
const ISMOBILE = function () {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}
if (ISMOBILE()) {
  console.log('chufal')
  // setRem(750)
  setRem(window, window['lib'] || (window['lib'] = {}))
  window.onresize = function () {
    // setRem(750)
    setRem(window, window['lib'] || (window['lib'] = {}))
  }
}

createApp(App).use(store).use(router).use(PCUI).use(MobileUI).mount('#app')
