import { createRouter, createWebHashHistory } from 'vue-router'
import { PCRouter, MpblieRouter } from './router'

const ISMOBILE = function () {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}

const routes = ISMOBILE() ? MpblieRouter : PCRouter

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
