export const PCRouter = [
  {
    path: '/',
    redirect: '/home',
    name: 'PCLayout',
    component: () => import(/*WebpackChunkName layout */ '@/views/pc/layout.vue'),
    children: [
      // 首页
      {
        path: '/home',
        name: 'home',
        component: () => import(/*WebpackChunkName layout */ '@/views/pc/home/index.vue'),
      },
      // 联系我们
      {
        path: '/contact',
        name: 'contact',
        component: () => import(/*WebpackChunkName layout */ '@/views/pc/contact/index.vue'),
      },
      // 关于我们
      {
        path: '/about',
        name: 'about',
        component: () => import(/*WebpackChunkName layout */ '@/views/pc/about/index.vue'),
      },
      // 产品服务
      {
        path: '/case',
        name: 'case',
        component: () => import(/*WebpackChunkName layout */ '@/views/pc/case/index.vue'),
      },
      // 科研进展
      {
        path: '/research',
        name: 'research',
        component: () => import(/*WebpackChunkName layout */ '@/views/pc/research/index.vue'),
      },
      {
        path: '/details',
        name: 'details',
        component: () => import(/*WebpackChunkName layout */ '@/views/pc/research/researchDetails.vue'),
      },
    ],
  },
]
export const MpblieRouter = [
  {
    path: '/',
    redirect: '/home',
    name: 'MobileLayout',
    component: () => import(/*WebpackChunkName layoutMobile */ '@/views/mobile/layoutMobile.vue'),
    children: [
      // 首页
      {
        path: '/home',
        name: 'home',
        component: () => import(/*WebpackChunkName layoutMobile */ '@/views/mobile/home/index.vue'),
      },
      // 关于我们
      {
        path: '/about',
        name: 'about',
        component: () => import(/*WebpackChunkName layoutMobile */ '@/views/mobile/about/index.vue'),
      },
      // 联系我们
      {
        path: '/contact',
        name: 'contact',
        component: () => import(/*WebpackChunkName layoutMobile */ '@/views/mobile/contact/index.vue'),
      },
      // 产品服务
      {
        path: '/case',
        name: 'case',
        component: () => import(/*WebpackChunkName layoutMobile */ '@/views/mobile/case/index.vue'),
      },
      // 科研进展
      {
        path: '/research',
        name: 'research',
        component: () => import(/*WebpackChunkName layoutMobile */ '@/views/mobile/research/index.vue'),
      },
      {
        path: '/details',
        name: 'details',
        component: () => import(/*WebpackChunkName layoutMobile */ '@/views/mobile/research/researchDetails.vue'),
      },
    ],
  },
]
